import React from 'react';
import { Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import {
  SupportButton,
  SupportTextList,
  SupportLinks,
  SupportMultipleLinkCard,
} from 'tmna-ui-kit';


import VideocamIcon from '@material-ui/icons/Videocam';

const RELATED_DOCS_SUPPORT_LINKS = [
  {
    to: '/docs/default/Component/ACE-TECH-DOCS/catalog/introduction/',
    title: 'What is App Catalog?',
  },
  {
    to: '/docs/default/Component/ACE-TECH-DOCS/catalog/features/ecosystem-modeling/',
    title: 'App Catalog Ecosystem',
  },
  {
    to: '/docs/default/Component/ACE-TECH-DOCS/catalog/features/component-types/',
    title: 'Component Types',
  },
  {
    to: '/docs/default/Component/ACE-TECH-DOCS/catalog/features/navigating-catalog/',
    title: 'Navigating App Catalog',
  },
  {
    to: '/docs/default/Component/ACE-TECH-DOCS/catalog/features/repo-assocations/',
    title: 'Associating Subcomponents',
  },
  {
    to: '/docs/default/Component/ACE-TECH-DOCS/catalog/definitions/yaml-file/',
    title: 'YAML File Fields',
  },
  {
    to: '/docs/default/Component/ACE-TECH-DOCS/catalog/contact-us/',
    title: 'Contact Us',
  },
  {
    to: '/docs/default/Component/ACE-FAQ/app-catalog/app-catalog-faq/',
    title: 'App Catalog FAQ',
  }
];



const RELATED_VIDEOS_SUPPORT_LINKS = [
  {
    to: 'https://myteams.toyota.com/:v:/r/sites/NA-108/Chofer%20Videos/App-Repo.mp4?csf=1&web=1&e=xyQl5A',
    title: 'Link Repos to Application',
  },
  {
    to: 'https://myteams.toyota.com/:v:/r/sites/NA-108/Chofer%20Videos/App-Cloud-Account.mp4?csf=1&web=1&e=0PFmqm',
    title: 'Link Cloud Accounts to Application',
  }
  
];

export const CatalogSupportButton = () => {
  return (
    <SupportButton>
      <SupportTextList>
        <Typography variant="body2">
          Centralized register that provides ownership and metadata related
          information for all software in TMNA.
        </Typography>
      </SupportTextList>
      <SupportLinks>
        <SupportMultipleLinkCard
          icon={<DescriptionIcon />}
          title="Related Docs"
          items={RELATED_DOCS_SUPPORT_LINKS}
        />
      </SupportLinks>



              <SupportLinks>
                      <SupportMultipleLinkCard
                        icon={<VideocamIcon />}
                        title="Related Videos"
                        items={RELATED_VIDEOS_SUPPORT_LINKS}
                      />
                    </SupportLinks>
    </SupportButton>
  );
};

export default CatalogSupportButton;
