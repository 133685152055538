import React from 'react';
import {
    Box,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';
import { Content } from '../Content';
import { HeaderLink } from './components';

import { useStyles } from './styles';
import { HeaderIconButton } from '../HeaderIconButton';
import { ProductRoadmapButton } from '../Product-Roadmap-Button';
import { SurveyButton } from '../SurveyButton';

interface HeaderProps {
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    additionalControls?: JSX.Element;
    extendedContent?: JSX.Element;
    displayButton?: any;
    backToLink?: {
        to: string;
        label: string;
    };
}

export const EntityPageHeader = ({
    title,
    subtitle,
    additionalControls,
    extendedContent,
    backToLink,
   // displayButton,
}: HeaderProps) => {
    const classes = useStyles();
    if(title==='ACE-FAQ'){
        subtitle="A place to have your questions on TMNA Cloud Platform or Cloud Support answered.";
    }
    return (
        <header className={`${classes.container}  ${title==='ACE-FAQ'?'faqHeader':'enityPageHeader'}`} >
            <Content className={classes.content}>
                <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
                    <Grid item container direction="column" spacing={3}>
                        {backToLink  && title!=='ACE-FAQ'&& <Grid item>
                            <HeaderLink
                                to={backToLink.to}
                                text={backToLink.label}
                            />
                        </Grid>}
                        <Grid item>
                            <Typography variant='h1'>
                                {title==='ACE-FAQ'?'FAQs':title}
                            </Typography>
                            {subtitle && <Typography className={classes.subtitle} variant='subtitle1'>
                                {subtitle}
                            </Typography>}
                        </Grid>
                        {extendedContent && <Grid item className={classes.extendedContent}>
                            {extendedContent}
                        </Grid>}
                    </Grid>
                    <Grid item className={`${title==='ACE-FAQ'?'faqIcons':''}`}>
                     {title!=='ACE-FAQ' && additionalControls}
                    </Grid>
                </Grid>
                <Grid container style={{display: 'flex', justifyContent:'flex-end' }}>
                    
                        <Box mx={0}>
                            <ProductRoadmapButton />
                        </Box>
                        <Box mx={0}>
                            <HeaderIconButton />
                        </Box>
                        <Box mx={0}>
                            <SurveyButton />
                        </Box>
                </Grid>
            </Content>
            <Box mx={5}>
                <Divider />
            </Box>
        </header>
    )
};
