import React from 'react';
import {
    Box,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';
import { Content } from '../Content';
import { HeaderLink } from './components';

import { HeaderIconButton } from '../HeaderIconButton';
import { ProductRoadmapButton } from '../Product-Roadmap-Button';



import { useStyles } from './styles';
import { SurveyButton } from '../SurveyButton';

interface HeaderProps {
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    additionalControls?: JSX.Element;
    extendedContent?: JSX.Element;
    iconButton?: JSX.Element;
    backgroundImg?: string;
    backToLink?: {
        to: string;
        label: string;
    };
    displayButton?: boolean
}

export const CatalogPageHeader = ({
    title,
    subtitle,
    additionalControls,
    extendedContent,
    backgroundImg,
    backToLink,
    displayButton = false,
}: HeaderProps) => {
    const classes = useStyles();

    const backgroundStyle = React.useMemo(() => (
        backgroundImg
            ? { backgroundImage: `url(${backgroundImg})` }
            : {}
    ), [backgroundImg]);

    // const extendedContentPresent = extendedContent != null;
    // const buttonContainerStyle = React.useMemo(() => (
    //      extendedContentPresent
    //         ? { marginTop: '-250px' } // Adjust the value as needed
    //         : {}
    // ), [backToLink, extendedContentPresent]);

    return (
        <header className={`${title === 'Chargeback for Cloud Account(s)' || title === 'Spend Rate Graph for Cloud Account(s)' ? classes.fixedHeight : ''} ${classes.container} ${classes['container--catalog']} catalogPageHeader`} style={backgroundStyle}>
            <Content className={classes.content}>
                <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
                    <Grid item container direction="column" spacing={3}>
                        {backToLink && <Grid item>
                            <HeaderLink
                                to={backToLink.to}
                                text={backToLink.label}
                            />
                        </Grid>}
                        <Grid item>
                            <Typography variant='h1'>
                                {title}
                            </Typography>
                            {subtitle && <Typography className={classes.subtitle} variant='subtitle1'>
                                {subtitle}
                            </Typography>}
                        </Grid>
                    </Grid>
                    <Grid item className={classes.additionalControls}>
                        {additionalControls}
                    </Grid>
                </Grid>
                {displayButton && (
                    <Grid container justifyContent="flex-end" 
                    //style={buttonContainerStyle}
                    >
                    <Grid item>
                        <Box display="flex">
                        <Box mx={0}>
                            <ProductRoadmapButton />
                        </Box>
                        <Box mx={0}>
                            <HeaderIconButton />
                        </Box>
                        <Box mx={0}>
                            <SurveyButton />
                        </Box>
                        </Box>
                    </Grid>
                    </Grid>
                )}
            </Content>
            {
                extendedContent &&
                <>
                    <Box mx={5}>
                        <Divider light />
                    </Box>
                    <Content className={classes.extendedContent}>
                        {extendedContent}
                    </Content>
                </>
            }
        </header>
    )
};